/* eslint-disable indent */
import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import useSite from 'hooks/use-site'
import styles from './site-header.module.css'

const SiteHeader = ({ variant = 'light' }) => {
  const { siteMetadata } = useSite()

  return (
    <header className={styles.header}>
      <nav className="grid">
        <div className="grid-span-8 grid-small-span-10">
          <Link
            className={styles.headerTitle}
            to="/"
          >
            {siteMetadata.title}
          </Link>
        </div>

        <NavLink
          className={styles.headerLinkPosts}
          to="/posts"
        >
          Posts
        </NavLink>
        <NavLink
          className={styles.headerLinkWork}
          to="/projects"
        >
          Work
        </NavLink>
      </nav>
    </header>
  )
}

const NavLink = ({ to, className, children }) => (
  <div className="grid-span-1 grid-small-span-2">
    <Link
      to={to}
      className={`${styles.headerLink} ${className}`}
      activeClassName={styles.headerLinkActive}
    >
      {children}
    </Link>
  </div>
)

export default SiteHeader
