import React from 'react'
import styles from './mdx-inline-comment.module.css'

export default function MDXInlineComment({ children }) {
  return (
    <div className="grid">
      <div className="grid-span-6">
        <div className={styles.mdxInlineComment}>
          <small>{children}</small>
        </div>
      </div>
    </div>
  )
}