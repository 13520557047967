import React from 'react'
import Helmet from 'react-helmet'
import useSite from 'hooks/use-site'

/**  
 * Default HTML metadata for the site
 */
const SiteMeta = () => {
  const { siteMetadata } = useSite()

  return (
    <Helmet>
      <title>{siteMetadata.title}</title>
      <meta name="description" content={siteMetadata.description} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content={`@${siteMetadata.twitter}`} />
      <meta name="twitter:creator" content={`@${siteMetadata.twitter}`} />
      <meta property="og:site_name" content={siteMetadata.title} />
      <meta property="og:type" content="website" />
      <link rel="icon" type="image/png" href="assets/icons/96x96.png" sizes="96x96" />
      <link rel="icon" type="image/png" href="assets/icons/32x32.png" sizes="32x32" />
      <link rel="icon" type="image/png" href="assets/icons/16x16.png" sizes="16x16" />
    </Helmet>
  )
}

export default SiteMeta
