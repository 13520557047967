import React from 'react'
import useSite from 'hooks/use-site'
import styles from './site-footer.module.css'

const SiteFooter = ({ path }) => {
  const { siteMetadata } = useSite()
  const year = new Date().getFullYear()

  return (
    <footer className={styles.footer}>
      <div className="grid">
        <div className="grid-span-9 grid-medium-span-12 grid-small-span-12">
          <div aria-label={`Copyright ${year} ${siteMetadata.title}`}>
            {String.fromCharCode(169)}{year}
            {path !== '/' && (<>&nbsp;{siteMetadata.title}</>)}
          </div>
        </div>
        <div className="grid-span-1 grid-medium-span-12 grid-small-span-12">
          <a
            className={styles.footerLink}
            href={siteMetadata.github}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={`${siteMetadata.title} on GitHub`}
          >
            GitHub
          </a>
        </div>
      </div>
    </footer>
  )
}

export default SiteFooter
