import React from 'react'
import { MDXProvider } from '@mdx-js/react'
import SiteFooter from 'components/site/site-footer'
import SiteHeader from 'components/site/site-header'
import SiteMeta from 'components/site/site-meta'
import * as MDXTypography from 'components/mdx/mdx-typography'
import MDXGrid from 'components/mdx/mdx-grid'
import MDXInlineComment from 'components/mdx/mdx-inline-comment'
import MDXBigQuote from 'components/mdx/mdx-big-quote'
import styles from './index.module.css'

const Site = ({ children, path, variant, ...props }) => {
  // MDX Components
  // https://mdxjs.com/table-of-components#table-of-components
  const components = {
    h1: MDXTypography.H1,
    h2: MDXTypography.H2,
    h3: MDXTypography.H3,
    p: MDXTypography.P,
    ul: MDXTypography.Ul,
    ol: MDXTypography.Ol,
    MDXGrid,
    MDXInlineComment,
    MDXBigQuote
  }

  return (
    <MDXProvider components={components}>
      <SiteMeta />
      <div className={styles.mainContainer}>
        {props.disableHeader !== true && <SiteHeader path={path} variant={variant} />}
        <main className={styles.main}>
          {children}
        </main>
        {props.disableFooter !== true && <SiteFooter path={path} />}
      </div>
    </MDXProvider>
  )
}

export default Site
