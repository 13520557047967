import React from 'react'
import MDXGrid from 'components/mdx/mdx-grid'

export function H1(props) {
  return <MDXGrid><h1 {...props} /></MDXGrid>
}

export function H2(props) {
  return <MDXGrid><h2 {...props} style={{ marginTop: '5rem', marginBottom: '1rem' }} /></MDXGrid>
}

export function H3(props) {
  return <MDXGrid><h3 {...props} style={{ marginTop: '3rem' }} /></MDXGrid>
}

export function P(props) {
  return <MDXGrid><p {...props} /></MDXGrid>
}

export function Ul(props) {
  return <MDXGrid><ul {...props} /></MDXGrid>
}

export function Ol(props) {
  return <MDXGrid><ol {...props} /></MDXGrid>
}
