import React from 'react'
import styles from './mdx-big-quote.module.css'

export default function MDXBigQuote({ cite, children }) {
  return (
    <div className={styles.mdxBigQuote}>
      <div className="grid">
        <div className="grid-span-10">
          <blockquote>{children}</blockquote>
          <cite>{cite}</cite>
        </div>
      </div>
    </div>
  )
}