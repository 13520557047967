import React from 'react'

export default function MDXGrid(props) {
  return (
    <div className="grid">
      <div className="grid-span-7 grid-medium-span-10 grid-small-span-10">
        {props.children}
      </div>
    </div>
  )
}