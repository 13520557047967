import { useStaticQuery, graphql } from 'gatsby'

const useSite = () => {
  const data = useStaticQuery(graphql`
    query Site {
      site {
        siteMetadata {
          canonicalUrl
          title
          description
          author
          email
          twitter
          github
          linkedin
          internalSettings {
            enablePortfolioProjects
            enableOpenSourceProjects
            enableExperienceList
          }
        }
      }
    }
  `)

  return data.site
}

export default useSite
